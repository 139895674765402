import React, { useState } from "react";
import Frame from "./Frame";
import useIsMobileDisplay from "./hooks/useIsMobileDisplay";

function App() {
  const [src, setSrc] = useState<undefined | string>(undefined);
  const [mode, setMode] = useState<"widget" | "embed">("widget");
  const isMobileDisplay = useIsMobileDisplay();
  const iframeStyles: Record<string, string> = isMobileDisplay
    ? {
        width: "100%",
        left: "0px",
        right: "0px",
        bottom: "0px",
      }
    : {};

  React.useEffect(() => {
    const handleReceiveMessage = (event: { origin: string; data: any }) => {
      try {
        const jsonData = JSON.parse(event.data);

        if (jsonData.type === "showEmbed") {
          setMode("embed");
          let baseUrl = "http://localhost:3000";
          if (process.env.NODE_ENV === "production") {
            baseUrl = "https://askmore.ai";
          }
          let url = `${baseUrl}/interviews/a/${jsonData.interviewId}/embed`;
          const params = new URLSearchParams();
          if (jsonData.config?.userName) {
            params.append('name', jsonData.config.userName);
          }
          if (jsonData.config?.userEmail) {
            params.append('email', jsonData.config.userEmail);
          }
          const queryString = params.toString();
          if (queryString) {
            url += `?${queryString}`;
          }
          setSrc(url);
        }

        if (jsonData.type === "startInterview") {
          let baseUrl = "http://localhost:3000";
          if (process.env.NODE_ENV === "production") {
            baseUrl = "https://askmore.ai";
          }
          // const url = `${baseUrl}/interviews/a/${jsonData.interviewId}/embed?mode=widget`;
          let url = `${baseUrl}/interviews/a/${jsonData.interviewId}/embed`;
          const params = new URLSearchParams();
          params.append('mode', 'widget');
          if (jsonData.config?.userName) {
            params.append('name', jsonData.config.userName);
          }
          if (jsonData.config?.userEmail) {
            params.append('email', jsonData.config.userEmail);
          }
          const queryString = params.toString();
          if (queryString) {
            url += `?${queryString}`;
          }
          setSrc(url);
        }

        if (jsonData.type === "hideInterview") {
          setSrc(undefined);
        }
      } catch (err) {}
    };

    // Attach the event listener
    window.addEventListener("message", handleReceiveMessage);

    // Clean up the event listener
    return () => {
      try {
        window.removeEventListener("message", handleReceiveMessage);
      } catch (e) {}
    };
  }, []);

  if (!src) return null;

  return (
    <Frame
      src={src}
      mode={mode}
      iframeStyles={iframeStyles}
      iframeCallback={(event) => {
        try {
          const jsonData = JSON.parse(event);
          if (jsonData.type === "hideInterview") {
            setSrc(undefined);
          }
        } catch (err) {}
      }}
    >
      {mode !== "widget" ? (
        <div
          style={{
            display: "flex",
            width: "100%",
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
            background: "#F9F9F9",
          }}
        >
          Loading...
        </div>
      ) : <></>}
    </Frame>
  );
}

export default App;
