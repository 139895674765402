import { useState, useEffect } from "react";

const useIsMobileDisplay = (): boolean => {
  // Set a maximum width for mobile devices (you can adjust this value)
  // Function to check if the window is opened in a mobile-sized display
  const checkDisplaySize = (): void => {
    const windowWidth: number = window.parent.innerWidth;
    setIsMobile(windowWidth <= maxWidthForMobile);
  };
  const maxWidthForMobile: number = 768;

  // Define the state to store whether the display is mobile-sized or not
  const [isMobile, setIsMobile] = useState<boolean>(
    window.parent.innerWidth <= maxWidthForMobile
  );

  useEffect(() => {
    // Check the display size initially
    checkDisplaySize();

    // Add a listener for window resize events
    window.parent.addEventListener("resize", checkDisplaySize);

    // Clean up the listener on component unmount
    return () => {
      try {
        window.parent.removeEventListener("resize", checkDisplaySize);
      } catch (e) {}
    };
  }, []);

  return isMobile;
};

export default useIsMobileDisplay;
