import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import { createPortal } from "react-dom";

const parentDocument = window.parent.document;

// TODO: Style depending on the mode
const IFRAME_STYLE_JSON: { [key: string]: string } = {
  // position: "fixed",
  overflow: "none",
  "z-index": "99999999999",
  // border: "1px solid var(--amGrey900, #33312E)",
  border: "none",
  outline: "none",
  // "background-color": "#33312E",
  // bottom: "12px",
  // right: "12px",
  // "border-radius": "12px",
  height: "240px",
  width: "528px",
  background: "#F9F9F9",
  "box-shadow":
    "0px 8px 24px 0px rgba(52, 45, 40, 0.14), 0px 3px 2px 0px rgba(52, 45, 40, 0.12)",
};

const Frame = (props: {
  children: React.ReactElement;
  src?: string;
  iframeStyles: Record<string, string>;
  iframeCallback?: (args: string) => void;
  mode: "widget" | "embed";
}) => {
  const [node, setNode] = useState<undefined | Document>();

  const iframeRef = React.useRef<HTMLIFrameElement | null>(null);
  const iframeParentRef = React.useRef<HTMLElement | null>(null);

  const handleReceiveMessage = (event: { origin: string; data: any }) => {
    if (props.iframeCallback) props.iframeCallback(event.data);
  };

  let styleStrings = "";

  if (props.mode === "embed") {
    IFRAME_STYLE_JSON.background = '#F9F9F9';
    // IFRAME_STYLE_JSON.border = '1px solid var(--amGrey200, #E7E3DD)';
    IFRAME_STYLE_JSON.position = 'relative';
    IFRAME_STYLE_JSON.bottom = 'unset';
    IFRAME_STYLE_JSON.right = 'unset';
    IFRAME_STYLE_JSON.width = '100%';
    IFRAME_STYLE_JSON.height = '100%';
    IFRAME_STYLE_JSON["box-shadow"] = 'none';
    for (let key in IFRAME_STYLE_JSON) {
      styleStrings += key + ": " + IFRAME_STYLE_JSON[key] + "; ";
    }

  } else {
    IFRAME_STYLE_JSON.position = 'fixed';
    IFRAME_STYLE_JSON.bottom = '16px';
    IFRAME_STYLE_JSON.right = '16px';
    IFRAME_STYLE_JSON.border = '1px solid var(--amGrey900, #33312E)';
    IFRAME_STYLE_JSON["border-radius"] = '12px';
    // TODO Try to move the isMobile check directly here and not use a props
    const mergedStyles = { ...IFRAME_STYLE_JSON, ...props.iframeStyles };
    for (let key in mergedStyles) {
      styleStrings += key + ": " + mergedStyles[key] + "; ";
    }

  }

  const createIframe = useCallback(
    (iframeTargetNode: HTMLElement) => {
      let iframe = parentDocument.createElement("iframe");
      iframe.setAttribute("style", styleStrings);
      if (props.src) {
        iframe.src = props.src;
      }
      iframe.className = "askmore-iframe";
      iframeTargetNode.appendChild(iframe);
      iframeParentRef.current = iframeTargetNode;
      iframeRef.current = iframe;

      // Attach the event listener
      iframeRef.current.contentWindow?.addEventListener(
        "message",
        handleReceiveMessage
      );

      const iframeDoc = iframe.contentDocument;

      if (iframeDoc) {
        const styleEl = iframeDoc.createElement("style");
        iframeDoc.head.appendChild(styleEl);
      }

      const body = iframeDoc?.getElementsByTagName("body")[0];
      body?.setAttribute(
        "style",
        "height: 100%; width: 100%; padding: 0px; margin: 0px;"
      );
      setNode(iframeDoc || undefined);
    },
    [props.src, styleStrings]
  );

  React.useEffect(() => {
    // Clean up the event listener
    return () => {
      try {
        iframeRef.current?.contentWindow?.removeEventListener(
          "message",
          handleReceiveMessage
        );
      } catch (e) {}
    };
  }, []);

  useLayoutEffect(() => {
    if (iframeRef.current) {
      iframeRef.current.setAttribute("style", `${styleStrings}; `);
    }
  }, [styleStrings]);

  useEffect(() => {
    const iframeTargetNode = props.mode === "widget" ? parentDocument.body : parentDocument.querySelector('div[data-am-embed]');

    if (iframeTargetNode) {
      createIframe(iframeTargetNode as HTMLElement);
    }
    return () => {
      if (iframeRef.current) {
        iframeRef.current.remove();
      }
    };
  }, [createIframe]);

  if (node) {
    return createPortal(<>{props.children}</>, node.body);
  }
  return null;
};

export default Frame;
